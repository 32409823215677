// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/image/marquee_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/* Globals vuetify 客製變數 */\n.background[data-v-793daf34] {\n  background: center/cover no-repeat url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), #222;\n}\n.object-fit[data-v-793daf34] {\n  -o-object-fit: cover;\n     object-fit: cover;\n}", ""]);
// Exports
module.exports = exports;
